import { SVGProps, Ref, forwardRef } from 'react'

const SvgFamily = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.75 1.5A1.25 1.25 0 0 0 1.5 2.75v18.5a1.25 1.25 0 0 0 1.25 1.25H22.5v-21H5.254v9.173a.75.75 0 0 1-1.5 0V1.5H2.75ZM4.504 0H2.75A2.75 2.75 0 0 0 0 2.75v18.5A2.75 2.75 0 0 0 2.75 24h20.5a.75.75 0 0 0 .75-.75V.75a.75.75 0 0 0-.75-.75H4.504Zm6.244 5.682a1.5 1.5 0 1 1 3 0 1.5 1.5 0 0 1-3 0Zm1.5-3a3 3 0 0 0-1.639 5.513 5.249 5.249 0 0 0-2.905 2.362.75.75 0 0 0 1.299.75 3.75 3.75 0 0 1 6.495 0 .75.75 0 1 0 1.299-.75 5.25 5.25 0 0 0-2.909-2.363 3 3 0 0 0-1.64-5.512ZM8.9 14a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Zm-3 1.5a3 3 0 1 1 4.645 2.51 5.25 5.25 0 0 1 2.254 1.47 5.254 5.254 0 0 1 2.256-1.467 3 3 0 1 1 3.277 0 5.255 5.255 0 0 1 2.907 2.362.75.75 0 0 1-1.299.75 3.75 3.75 0 0 0-6.463-.052.746.746 0 0 1-.673.427.746.746 0 0 1-.684-.428 3.75 3.75 0 0 0-6.47.052.75.75 0 0 1-1.3-.748 5.25 5.25 0 0 1 2.905-2.367A2.997 2.997 0 0 1 5.9 15.5ZM16.693 14a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3Z"
      clipRule="evenodd"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgFamily)
export default ForwardRef
